@tailwind base;
@tailwind components;
@tailwind utilities;

.responsive-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.responsive-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

body.dark-mode .custom-prose span[style*="background-color:white"] {
   @apply text-gray-800 !important; /* white background text in black */ 
}

.custom-prose h2, h1{
  @apply text-lg mt-8 mb-4 font-bold;
}

.custom-prose h4, h3 {
  @apply font-semibold text-[1rem]
}

.custom-prose p {
  @apply mb-6 leading-relaxed;
}

.custom-prose ol {
  @apply list-decimal pl-6 mb-6;
}

.custom-prose li {
  @apply mb-4;
}

 
.custom-prose pre {
  @apply bg-white p-2 rounded-md overflow-x-auto text-center;
}

body.dark-mode .custom-prose pre {
  @apply bg-zinc-300 p-2 text-white rounded-md overflow-x-auto  text-center
}
@media screen and (max-width: 640px) {
    .custom-prose pre {
    @apply text-[0.749rem];  
  }
  
}

.custom-prose code {
  @apply p-2 text-gray-700 rounded-md overflow-x-scroll  text-center italic
}

body.dark-mode .custom-prose p code {
  @apply   p-2 text-gray-400 rounded-md overflow-x-scroll  text-center italic
}

.custom-prose blockquote p{
  @apply text-black
}

body.dark-mode .custom-prose blockquote p{
  @apply text-white  !important;
}

.custom-prose strong {
  @apply text-gray-900 !important;
}  

body.dark-mode .custom-prose span[style*="background-color:white"][style*="color:#273239"]  strong {
  @apply text-gray-800  font-semibold  !important;
}

body.dark-mode .custom-prose span[style*="background-color:rgb(255,255,255)"][style*="color:rgb(102,102,102)"]{
  @apply text-gray-800  font-semibold  !important;
} 

body.dark-mode .custom-prose span[style*="background-color:white"][style*="color:#0A0A23"] strong {
  @apply text-gray-800 !important
}


body.dark-mode .custom-prose strong {
  @apply text-white  !important;
}

body.dark-mode .custom-prose div strong {
  @apply text-black  !important;
}

.custom-prose h3 {
  @apply text-white  !important;  
}

body.dark-mode .custom-prose blockquote,
body.dark-mode .custom-prose p,
body.dark-mode .custom-prose ul,
body.dark-mode .custom-prose li {
  @apply text-zinc-300   !important;
}
 
body.dark-mode .custom-prose li,
body.dark-mode .custom-prose p span,
body.dark-mode .custom-prose li span {
  @apply text-zinc-300 !important;
}
